<template>
  <xref-filter :filter="filter" :multi="true"></xref-filter>
</template>

<script>
  import XrefFilter from './XrefFilter'

  export default {
    name: 'MultiXrefFilter',
    components: { XrefFilter },
    props: {
      filter: {
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>
