<template>
  <div class="map-dynamic-filter">
    <condition
      v-model="localCondition"
      :conditions="conditionOptions"
      @change="changeCondition"
      class="dynamic-filter-condition"
    ></condition>
    <el-select
      v-model="filter.value"
      size="mini"
      :multiple="true"
      collapse-tags
      filterable
      class="dynamic-filter-value"
      :disabled="['is_null', 'is_not_null'].includes(filter.condition)"
    >
      <el-option
        v-for="item in options"
        :value="item.value"
        :label="item.label"></el-option>
    </el-select>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { XrefAPI } from '@/services/RegistryTable/infrastructure/api/XrefAPI'
  import Condition from './Conditions'

  export default {
    name: 'XrefFilter',
    components: { Condition },
    props: {
      filter: {
        type: Object,
        required: true
      },
      multi: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        conditionOptions: this.multi ? ['equals_all', 'not_equals_all', 'is_null', 'is_not_null', 'equals_any', 'not_equals_any'] : ['equals_any', 'not_equals_any', 'is_null', 'is_not_null',],
        localCondition: null,
        options: []
      }
    },
    mounted () {
      this.$set(this, 'localCondition', this.filter.condition)
      this.loadOptions(this.filter)
    },
    methods: {
      changeCondition (newVal) {
        this.$set(this.filter, 'condition', newVal)
      },
      async loadOptions (filter) {
        let opt = await APIClient.shared.request(new XrefAPI.GetData(filter.field))
        let me = this
        opt.forEach((option) => {
          me.options.splice(me.options.length, 0, {
            label: option.name,
            value: option.id
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
